import React from 'react'

import { navigate } from 'gatsby'
import { Navigator as BaseNavigator } from '../Navigator'

class Navigator extends BaseNavigator {
  /**
   */
  redirectTo = (to, opts = {}) => {
    if(to.match(/^http/)) {
      window.location.href = to
      return 
    } 
    return navigate(to, {})
  }

  /**
   */
  forwardTo = (to, opts = {}) => {
    if(to.match(/^http/)) {
      window.location.href = to
      return 
    } 
    return navigate(to, { replace: true })
  }
}

const navigator = new Navigator()

export const useNavigate = () => ({
  navigator,
  redirectTo: navigator.redirectTo,
  forwardTo: navigator.forwardTo,
})

