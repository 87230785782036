import React from 'react'
import { navigate } from 'gatsby'

import { useLocation } from "@reach/router"

export const useLocaleRedirection = (path = "/") => {

  const location = useLocation()

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    const lang = params.get('lang') ?? 'ja'
    params.delete('lang')

    if (lang) {
      navigate(`/${lang}${path}?${params}`)
    } else {
      navigate(`/ja${path}?${params}`)
    }
  })
}
